import { invoke } from 'lodash';

import type { initialState as initialUserState } from '../../store/user/reducers';

type Value = string | null;
type HasChangedFn<T> = (patch: Partial<T>) => boolean;
type AttributeConstructor<T> = (result: T) => Value;
type AttributeDefinition<T> = [string, HasChangedFn<T>, AttributeConstructor<T>];
type UserStateProperty = keyof typeof initialUserState;
type UserState = Record<UserStateProperty, Value>;

const dependsOn =
  <T extends Record<string, Value>>(...dependencies: (keyof T)[]): HasChangedFn<T> =>
  (patch) =>
    dependencies.some((d) => {
      if (d in patch) {
        // Drop updates to null value from dependencies.
        //
        if (patch[d] !== null) {
          return true;
        }
      }
      return false;
    });

const identityProducer =
  <T extends Record<string, Value>>(dependency: keyof T): AttributeConstructor<T> =>
  (result) =>
    // @ts-expect-error -- enabling strict mode
    result[dependency];

const concatProducer =
  <T extends Record<any, Value>>(separator: string, ...dependencies: (keyof T)[]): AttributeConstructor<T> =>
  (result) =>
    dependencies
      .filter((d) => Boolean(d))
      .map((d) => result[d])
      .join(separator);

export const gtm = {
  userAttributes: [
    ['userEmail', dependsOn<UserState>('email'), identityProducer<UserState>('email')],
    [
      'userName',
      dependsOn<UserState>('first_name', 'last_name'),
      concatProducer<UserState>(' ', 'first_name', 'last_name'),
    ],
    ['userRole', dependsOn<UserState>('role'), identityProducer<UserState>('role')],
    ['userJobFunction', dependsOn<UserState>('job_function'), identityProducer<UserState>('job_function')],
    ['userCreationSource', dependsOn<UserState>('source'), identityProducer<UserState>('source')],
    ['companyName', dependsOn<UserState>('client'), identityProducer<UserState>('client')],
    ['companyWebsite', dependsOn<UserState>('client_domain'), identityProducer<UserState>('client_domain')],
  ] as AttributeDefinition<UserState>[],

  updateUserAttributes(patch: Partial<UserState>, result: UserState) {
    // Wrap this entire block in a try-catch to avoid
    // any nasty failures in a calling reducer.
    //
    try {
      const requiredAttributes = {
        event: 'updateUserAttributes',
        userIntercomHash: result.user_intercomHash,
        userId: result.user_id,
        companyId: result.client_id,
      };

      if (!requiredAttributes.userIntercomHash || !requiredAttributes.userId || !requiredAttributes.companyId) {
        return;
      }

      // If there is a patch
      //  && the next state will be authenticated
      //  && some of the properties included in the
      //     patch match variables we would like to
      //     keep up to date in GTM
      //
      if (patch && result.authenticated && this.userAttributes.some(([, shouldUpdate]) => shouldUpdate(patch))) {
        // Start with the required attributes, and for
        // each attribute wanted by GTM, see if it is
        // in the patch, if so produce our desired
        // value.
        //
        const eventData = this.userAttributes.reduce((event, [attr, shouldUpdate, produce]) => {
          if (shouldUpdate(patch)) {
            return {
              ...event,
              [attr]: produce(result),
            };
          }
          return event;
        }, requiredAttributes);

        invoke(window, ['dataLayer', 'push'], eventData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
};
